<template>
  <v-container>
    <v-row class="mx-2">
      <v-btn @click.native="Dialog = true" class="primary">Add</v-btn>
      <v-col v-if="PaymentModes" cols="12" sm="12" md="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="PaymentModes"
          :search="search"
        >
          <template v-slot:item.created_at="{ item }">{{
            item.created_at | moment("LLL")
          }}</template>
          <template v-slot:item.Active="{ item }">
            {{ item.Active ? "Active" : "In-Active" }}
          </template>

          <template v-slot:item.id="props">
            <v-btn
              @click="removeItem(props.item.id, 'assign_payments_modes')"
              color="white"
              plain
              small
            >
              <v-icon color="red">mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- one item selection modal -->
    <v-dialog v-model="Dialog" width="950">
      <v-toolbar dark color="primary">
        <v-toolbar-title>List of Payments Modes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="Dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-card-title>
                <v-col cols="6">
                  <v-autocomplete
                    :items="PayModes"
                    item-text="Name"
                    item-value="id"
                    outlined
                    v-model="Payment.PymtHeader"
                    return-object
                    label="Provider"
                    @input="getPaymentTypes($event)"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="paymentLines" cols="6">
                  <v-autocomplete
                    :items="paymentLines"
                    item-text="Name"
                    item-value="id"
                    outlined
                    v-model="Payment.PymtType"
                    return-object
                    label="Payment Method"
                    @input="getPaymentAccounts($event)"
                  ></v-autocomplete>
                </v-col>

                <v-col v-if="paymentAccounts" cols="6">
                  <v-autocomplete
                    :items="paymentAccounts"
                    item-value="id"
                    :item-text="(item) => item.Shortcode + '  -  ' + item.Name"
                    outlined
                    v-model="Payment.PymtAcct"
                    return-object
                    label="Account"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    outlined
                    dense
                    v-model="Payment.Active"
                    label="Active"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-btn color="accent" @click="saveData" :loading="loader">
                    <v-icon left>mdi-content-save</v-icon>Add
                  </v-btn>
                </v-col>
              </v-card-title>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item modal -->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    record: {},
    PayModes: [],
    selectedLine: null,
    paymentLines: null,
    selectedAcct: null,
    paymentAccounts: null,
    PaymentModes: [],
    Payment: {},
    PaymentAssgn: [],
    search: null,
    searchWhs: null,
    loader: false,
    Dialog: false,
    headers: [
      { text: "Provider", value: "provider.Name" },
      { text: "Mode", value: "pay_method.Name" },
      { text: "Account", value: "acct.Shortcode" },
      { text: "Location", value: "acct.Name" },
      { text: "Active", value: "Active" },
      { text: "Created On", value: "created_at" },
      { text: "Actions", value: "id" },
    ],
  }),
  methods: {
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },

    getData() {
      const self = this;
      this.loading = true;
      self.loading = false;
      this.$store
        .dispatch("get", `/payments/incoming/third-party/modes`)
        .then((res) => {
          self.PayModes = res.ResponseData;
          self.loading = false;
          this.loading = false;
        })
        .catch((err) => {
          self.loading = false;
          this.$refs.snackbar.show(err, "red");
        });
    },

    paymentsModesCollection() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee/${this.$route.params.empID}/paymentsModesCollection`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.PaymentModes = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          self.loading = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    getPaymentTypes(item) {
      this.selectedLine = item.Name;
      this.paymentLines = item.tpp1;
      this.paymentAccounts = [];
    },
    getPaymentAccounts(item) {
      this.selectedAcct = item.Name;
      this.paymentAccounts = item.tpp2;
    },
    saveData() {
      let data = this.Payment;
      const url = `/assign_payments_modes/${this.$route.params.empID}`;

      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          this.Dialog = false;
          console.log(res);
          location.reload();
        })
        .catch((err) => {
          self.$store.commit("loader", false);
          self.$refs.snackbar.show(err.response?.data?.ResultDesc, "red");
        });
    },

    removeItem(id, baseurl) {
      var userResponse = confirm(
        "Are you sure you want to delete? This action cannot be undone."
      );
      if (userResponse === true) {
        const url = `${baseurl}/${id}`;
        const self = this;
        self.$store
          .dispatch("remove", url)
          .then((res) => {
            if (res.ResultCode == 1200) {
              location.reload();
            } else {
              self.loading = false;
              this.$refs.snackbar.show(res.ResultDesc, "red");
            }
            this.Dialog = false;
            this.WhsDialog = false;
          })
          .catch((err) => {
            self.$store.commit("loader", false);
            self.$refs.snackbar.show(err.response?.data?.ResultDesc, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },
  },
  created() {
    this.getData();
    this.paymentsModesCollection();
  },
};
</script>
